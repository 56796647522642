@import "./src/core/App.scss";

body {
  margin: 0;
  border-width: 0px;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s;
    -webkit-text-fill-color: #fff !important;
  }
}
.wait-container {
  text-align: center;
  margin: auto;
  display: block;
  margin-left: -40;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  position: relative;
  padding: 12px 16px;
  padding-left: 40px;
  color: white;
  line-height: 1.5715;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.ant-collapse-content > .ant-collapse-content-box {
  padding: 7px;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #f9fbfd;
  // color: #1890ff;
  // color: #ff1818;
  font-weight: 500;
  color: #f9fbfd;
}

.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
  margin: 0;
  padding: 8px 16px;
  background: #2e4fa5;
  color: #f9fbfd;
  border: 1px solid #f0f0f0;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-tabs-tab {
  font-size: 12px;
}

.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]
  .MuiAutocomplete-input {
  padding: 18.5px 4px;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  position: relative;
  padding: 12px 16px;
  padding-left: 40px;
  color: rgb(255 255 255 / 85%);
  line-height: 1.5715;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

// .MuiDrawer-paperAnchorDockedLeft {
//   border-right: 0.5px solid black;
// }

.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
  margin: 0;
  padding: 8px 16px;
  background: #2e4fa5;
  color: #f9fbfd;
  font-weight: 700;
  border: 1px solid rgba(0, 0, 0, 0.05);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}
.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab-active {
  border-bottom-color: #ea432d;
  border-width: 0px 0px 4px 0px;
  font-weight: 700;
  // border-left-width: 0px;
}

.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab {
  border-radius: 8px 8px 0px 0px;
  margin-left: 3px;
  font-weight: 700;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #f9fbfd;
  font-weight: 800;
  color: #f9fbfd;
}

.ant-collapse-borderless > .ant-collapse-item {
  border-bottom: 1px solid #b6b6b6b4;
  // border-bottom: 1px solid #5E8AFC;
  border-width: 2px;
  margin-bottom: 1px;
}

.ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-bottom > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before,
.ant-tabs-bottom > div > .ant-tabs-nav::before {
  position: absolute;
  right: 0;
  left: 0;
  border-bottom: 1px solid transparent;
  content: "";
}

/* For vertical scrollbars */
::-webkit-scrollbar {
  width: 4px; /* Vertical scrollbar width */
  height: 0px; /* Horizontal scrollbar height */
  margin-right: 10px;
}

/* For horizontal scrollbars */
::-webkit-scrollbar-horizontal {
  height: 4px; /* Set horizontal scrollbar height */
}

/* Style the scrollbar thumb */
::-webkit-scrollbar-thumb {
  background-color: #3e4fa1; /* Color of the scrollbar handle */
  border-radius: 10px; /* Round corners */
}

/* Style the scrollbar track */
::-webkit-scrollbar-track {
  background: #6287e8; /* Background of the scrollbar track */
}

// .MuiIconButton-root {
//   flex: 0 0 auto;
//   color: white;
//   padding: 12px;
//   overflow: visible;
//   font-size: 1.5rem;
//   text-align: center;
//   transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
//   border-radius: 50%;
// }
